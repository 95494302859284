import 'animate.css'

export default () => {
  return (e: any, animation: string) => {
    e.target?.children?.[0]?.classList?.add('animate__animated', `animate__${animation}`)
    e.target?.children?.[0]?.addEventListener('animationend', () => {
      e.target?.children?.[0]?.classList?.remove('animate__animated', `animate__${animation}`)
      e.target?.children?.[0]?.removeEventListener('animationend', () => {})
    })
  }
}
